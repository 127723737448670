<template>
  <div>
    <b-modal
      @show="showModal"
      @hidden="hideModal"
      id="my-modal"
      ref="my-modal"
      class="rounded-0"
      centered
      size="lg"
      title="Banner Güncelle"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12" :md="diller.length != 1 && 6" v-show="diller.length != 1">
            <b-form-group label="Dil">
              <v-select
                v-model="defaultDil"
                :options="diller"
                :reduce="(diller) => diller.lang"
                label="title"
                :clearable="false"
                :disabled="!form.k_no"
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Konum" description="Gösterim Alanı">
              <v-select
                v-model="form.konum"
                :options="bannerKonum"
                :reduce="(statu) => statu.id"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Başlık">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.icerik[defaultDil].baslik"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Aciklama">
              <b-form-textarea
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.icerik[defaultDil].aciklama"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Url" description="Bannera Tıklandıgında gideceği url'yi yazınız.">
              <b-form-input size="lg" class="rounded-0" ref="baslik" v-model="form.icerik[defaultDil].url" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Yeni Sekme" description="Tıklandıgında yeni sekmede aç">
              <v-select
                v-model="form.sekme"
                :options="sekmeler"
                :reduce="(sekme) => sekme.id"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Statu" description="Gösterim durumu">
              <v-select
                v-model="form.statu"
                :options="statuler"
                :reduce="(statu) => statu.id"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" type="submit" @click="onSubmit" :disabled="show">
            <i class="fad fa-save" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Güncelle
          </b-button>
          <b-button squared variant="danger" class="float-right mr-1" @click="handlerRemove(form.k_no)">
            <i class="fad fa-trash" />
            Sil
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import { ref, toRefs, watch, computed } from '@vue/composition-api';

export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.show = ref(false);

    expo.sekmeler = ref([
      { id: false, title: 'Hayır' },
      { id: true, title: 'Evet' },
    ]);
    expo.statuler = ref([
      { id: true, title: 'Aktif' },
      { id: false, title: 'Pasif' },
    ]);

    expo.bannerKonum = ref([
      { id: '1', title: 'Banner 1' },
      { id: '2', title: 'Banner 2' },
      { id: '3', title: 'Banner 3' },
    ]);

    expo.form = ref({
      _id: null,
      k_no: null,
      icerik: {},
      konum: 0,
      sekme: false,
      statu: true,
    });

    expo.showModal = () => {
      context.refs['my-modal'].show();
    };
    expo.hideModal = () => {
      context.refs['my-modal'].hide();
      context.emit('closeModal', true);
    };

    const createIcerikDilUret = () => {
      expo.diller.value.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = { baslik: null, aciklama: null };
      });
    };
    createIcerikDilUret();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    expo.onSubmit = () => {
      expo.show.value = true;
      store
        .dispatch('bannerGuncelle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            expo.show.value = false;
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.success == false) {
            toast.error(Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message, {
              position: 'bottom-left',
            });
          }
        });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('bannerSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Silme`,
                    icon: 'Trash2Icon',
                    variant: 'danger',
                    text: `Silme başarılı.`,
                  },
                },
                { position: POSITION.BOTTOM_RIGHT }
              );
              expo.hideModal();
            }
          });
        }
      });
    };

    const prop = toRefs(props);

    watch(
      prop.openModal,
      (val) => {
        if (val == true) {
          expo.form.value = { ...props.updateData };
          expo.showModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
